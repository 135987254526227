.newHaikuSection {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.textAndCounterFormNewHaiku {
    display: flex;
    flex-direction: row;
    width: 80%;
}

.textFormNewHaiku {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    margin-top: 40%;
    margin-right: 2%
}

.newHaikuLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin: auto;
}


.LineCounterFormNewHaiku {
    margin-top: 54%;
    line-height: 2em;
    color: aliceblue;
}

.selectEmojiMood {
    display: flex;
    flex-direction: column;
}

.emojiItem {
    height: 60px;
}

.emojiSelected {
    padding-top: -30px;
    height: 60px;
}

.selectEmojiTitle {
    display: flex;
    align-items: center;
    align-content: center;
}

.ReglesHaiku{
    width: 90%;
    display: flex;
    justify-content: flex-start;
}

.ReglesHaiku li {
    text-decoration: none;
}