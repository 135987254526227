.filterButton {
    font-size: 18px;
}

.moodButtonIcone {
    font-size: 18px;
}

.NewHaikuButtonIcone {
    width: 76px;
}

.backgroundfooterButton{
    height: 60px;
}

.footerButtons{
    margin-left: 20px;
}